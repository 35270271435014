import React, { useState, useRef, useEffect } from "react";
import {
  Select,
  Button,
  DatePicker,
  Tooltip,
  message,
  Form,
  Input,
  Row,
  Col,
  Card,
  FloatButton,
  Table,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  DeleteTwoTone,
  DownloadOutlined,
  SaveOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import LoadingSpinner from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomerSingleQuote,
  setActiveQuote,
  updateCustomerQuoteProposal,
} from "../store/reducers/authReducer";
const { TextArea } = Input;

dayjs.extend(customParseFormat);
const dateFormat = "DD-MMMM-YYYY";
const today = dayjs().format(dateFormat);
const oneYearAgo = dayjs().subtract(2, "year").format(dateFormat);

const QuoteComparison = () => {
  const [uploading, setUploading] = useState(false);
  const [initialData, setInitialData] = useState([{}]);
  const reportTemplateRef = useRef(null);
  const dispatch = useDispatch();

  const agentAuthInfo = useSelector((state) => state?.auth);
  const agentAuthToken = useSelector((state) => state?.auth?.token);
  const [productSuggested, setproductSuggested] = useState("");

  const activeQuote = useSelector((state) => state?.auth?.activeQuote);

  const [form] = Form.useForm();

  const jsonData = [
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "HDFC ERGO",
      "Product Name": "Optima Secure",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes":
        "3 Years",
      "Entry Age Min & Max": "NA",
      "Unique Features": [
        "3X Cover (Inc. of Cu. Bonus)",
        "First Year-10 Lacs (5+5)",
        "Second Year-12.5 Lacs (10+2.5)",
        "Third year onwards-15 Lacs (12.5+2.5)",
        "(Irrespective of claim/No claim)",
      ],
      "Room Type": "Any category Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available-100% restoration for same person same illness - Unlimited Times",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "60 Days/180 Days",
      "Cost Of Consumables": "Covered",
      "Health Check up": "Covered",
      "Organ Donor Expenses":
        "Covered up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Covered Actual",
      "Air Ambulance Charges": "Up to 5 lacs",
      "Co - Payment": "No Co-Payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "NA",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Care Health",
      "Product Name": "Care Supreme",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes":
        "3 Years",
      "Entry Age Min & Max": "NA",
      "Unique Features": [
        "7X Cover (Inc. of CB)",
        "First Year-5 Lacs",
        "Second Year-12.5 Lacs ( 5+5+2.5 )",
        "Third year-20 Lacs (12.5+5+2.5)",
        "Fourth Year-25 Lacs (20+5)",
        "Fifth Year-30 Lacs (25+5)",
        "Sixth Year Onwards 35 Lacs (30+5)",
        "In five years SI becomes 7 Times",
        "(Irrespective of claim/No claim)",
      ],
      "Room Type": "Any category Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available-100% restoration for same person same illness - Unlimited Times",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "60 Days/180 Days",
      "Cost Of Consumables": "Optional at addl. Premium (Rs)",
      "Health Check up": "Optional",
      "Organ Donor Expenses":
        "Covered up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Up to Rs.10,000",
      "Air Ambulance Charges": "Up to 5 lacs",
      "Co - Payment": "No Co-Payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "NA",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Care Health",
      "Product Name": "Care Supreme Senior",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes":
        "3 Years",
      "Entry Age Min & Max": "NA",
      "Unique Features": ["50% of SI per year, max up to 100% of SI"],
      "Room Type": "Single Private A/C Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available-100% restoration for unrelated or same illness -Unlimited Times",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "60 Days/180 Days",
      "Cost Of Consumables": "Not Covered",
      "Health Check up": "Not Covered",
      "Organ Donor Expenses":
        "Covered up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Up to Rs.10,000",
      "Air Ambulance Charges": "Not Covered",
      "Co - Payment": "20% Co-Payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "Not Covered",
      Maternity: "Not Covered",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Care Health",
      "Product Name": "Care Heart",
      "Policy Period": "2 Years",
      "Sum Insured": "3 Lacs",
      Deductible: "0",
      "Unique Features":
       [ "10% increase in SI per policy year in case of claim-free year; Max up to 50% of SI (10% decrease in SI per policy year in case a claim has been paid; such decrease is only in SI accrued as NCB)" ],
      "Room Type": "Single Private A/C Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available - 100% restoration for same person same illness - Once in a policy year",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "2 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Not Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Not Covered",
      "Domiciliary Hospitalization": "Covered up to SI after 3 days",
      "Pre & Post Hospitalization": "30 Days / 60 Days; Maximum up to 5% of SI",
      "Cost Of Consumables": "Optional",
      "Health Check up": "Covered",
      "Organ Donor Expenses":
        "Covered Up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Not Covered",
      "Air Ambulance Charges": "Not Covered",
      "Co - Payment": "20%",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "Not Applicable",
      Maternity: "Not Covered",
    },

    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "ICICI Lombard",
      "Product Name": "Elevate",
      "Policy Period": "1 Year",
      "Sum Insured": "7.5 Lacs",
      Deductible: "0",
      "Unique Features":
       [ "20% of SI for every claim-free year up to 100%, will not reduce on future claims"],
      "Room Type": "Single Private A/C Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available - 100% restoration for the same person, same illness - Unlimited Times",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre & Post Hospitalization": "90 Days / 180 Days",
      "Cost Of Consumables": "Covered",
      "Health Check up": "Covered",
      "Organ Donor Expenses":
        "Covered Up to Sum Insured (For the donor, pre/post expenses not covered)",
      "Road Ambulance Charges": "Covered Actual",
      "Air Ambulance Charges": "Up to SI",
      "Co - Payment": "0%",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "Not Covered",
      Maternity:
        "Covers Normal & C-Section up to 10% of SI, max up to Rs. 1 Lac. 2 year waiting period",
    },

    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "TATA AIG",
      "Product Name": "Medicare",
      "Policy Period": "1 Year",
      "Sum Insured": "20 Lacs",
      Deductible: "0",
      "Unique Features":
       [ "50% increase in cumulative bonus for every claim-free year. In the case a claim is made during the policy year, the cumulative bonus would reduce by 50% in the following year." ],
      "Room Type": "Any category Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available - 100% restoration for the same person, same illness - Once in a policy year",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "2 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre & Post Hospitalization": "60 Days / 90 Days",
      "Cost Of Consumables": "Covered",
      "Health Check up": "Up to 1% of Sum Insured",
      "Organ Donor Expenses":
        "Covered Up to Sum Insured (For the donor, pre/post expenses not covered)",
      "Road Ambulance Charges": "Up to 5,000",
      "Air Ambulance Charges": "Up to 5 Lacs",
      "Co - Payment": "No Co-Payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "Not Covered",
      Maternity:
        "Rs 50,000 (Rs 60,000 for birth of girl child), waiting period of 4 years",
    },

    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "HDFC ERGO",
      "Product Name": "Energy",
      "Policy Period": "1 Year",
      "Sum Insured": "3 Lacs",
      Deductible: "0",
      "Unique Features":
        ["Cumulative Bonus 10% every claim-free year, Max. 100% of Base Sum Insured. In case of a claim, the deduction will also happen."],
      "Room Type": "Covered Actual",
      ICU: "Covered Actual",
      "Restore Benefit": "Up to base sum insured, once in a policy year.",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "No waiting period for ABCD",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Not covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Not covered",
      "Domiciliary Hospitalization": "Not covered",
      "Pre & Post Hospitalization": "30 Days / 60 Days",
      "Cost Of Consumables": "Not covered",
      "Health Check up": "Covered",
      "Organ Donor Expenses":
        "Covered Up to Sum Insured (For donor, pre/post expenses not covered)",
      "Road Ambulance Charges": "Rs 2000 per hospitalization",
      "Air Ambulance Charges": "Not covered",
      "Co - Payment": "0%",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "Not Covered",
      Maternity: "Not covered",
    },

    {
      "Insurance Plan": "Super Topup Plan",
      "Insurance Company": "ICICI Lombard",
      "Product Name": "Activate Booster",
      "Policy Period": "1 Year",
      "Sum Insured": "50 Lacs",
      Deductible: "3 Lacs",
      "Unique Features": ["Not Applicable"],
      "Room Type": "Single Private AC Room",
      ICU: "Covered Actual",
      "Restore Benefit": "No",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre & Post Hospitalization": "60 Days / 180 Days",
      "Cost Of Consumables": "Covered",
      "Health Check up": "Not Covered",
      "Organ Donor Expenses":
        "Covered Up to Sum Insured (For donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Covered Actual",
      "Air Ambulance Charges": "Not Covered",
      "Co - Payment": "No Co-Payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "Not Covered",
      Maternity: "Not covered",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Niva Bupa",
      "Product Name": "ReAssure 2.0",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes":
        "3 Years",
      "Entry Age Min & Max": "NA",
      "Unique Features": [
        "5X Cover.",
        "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
        "Lock the Clock",
        "Your age is locked at entry when you buy the policy, till you make a claim",
      ],
      "Room Type": "Any category Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available-100% restoration for same person same illness - Unlimited Times",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "60 Days/180 Days",
      "Cost Of Consumables": "Optional at addl. Premium (Rs)",
      "Health Check up": "Covered",
      "Organ Donor Expenses":
        "Covered up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Covered Actual",
      "Air Ambulance Charges": "Up to 2.5 lacs per hospitalisation",
      "Co - Payment": "No Co-Payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "NA",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Niva Bupa",
      "Product Name": "Senior First (Platinum)",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes":
        "2 Years",
      "Entry Age Min & Max": "NA",
      "Unique Features": [
        "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
        "It is not reduced even if there is a claim.",
      ],
      "Room Type": "Single Private Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available-100% restoration for same person same illness - Unlimited Times",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "2 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "60 Days/180 Days",
      "Cost Of Consumables": "Optional at addl. Premium (Rs)",
      "Health Check up": "Covered",
      "Organ Donor Expenses":
        "Covered up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Up to 2,000 per hospitalization",
      "Air Ambulance Charges": "up to INR 2,50,000 per hospitalization",
      "Co - Payment": "Optional",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "NA",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "ICICI Lombard",
      "Product Name": "Health Advantedge",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes":
        "2 Years",
      "Entry Age Min & Max": "NA",
      "Unique Features": [
        "20% of SI maximum upto 100%.",
        "(Irrespective of claim/No claim).",
      ],
      "Room Type": "Single Private Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "100% of the base SI shall be made available even in case of partial utilization of SI for hospitalization due to any illness for same person-Once in a policy year",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "2 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "60 Days/180 Days",
      "Cost Of Consumables": "Optional at addl. Premium (Rs)",
      "Health Check up": "Up to 0.5% of SI or up to max of ₹10,000",
      "Organ Donor Expenses":
        "Covered up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Up to 1% of SI per hospitalization",
      "Air Ambulance Charges": "Up to SI",
      "Co - Payment": "No Co-Payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "Up to ₹10,000",
      Maternity: "NA",
    },

    {
      "Insurance Plan": "Super Topup Plan",
      "Insurance Company": "HDFC ERGO",
      "Product Name": "Medisure",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes":
        "3 Years",
      "Entry Age Min & Max": "NA",
      "Unique Features": ["NA"],
      "Room Type": "Single Private AC Room",
      ICU: "Covered Actual",
      "Restore Benefit": "NA",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "30 Days/60 Days",
      "Cost Of Consumables": "Not Covered",
      "Health Check up": "Not Covered",
      "Organ Donor Expenses":
        "Covered up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Not Covered",
      "Air Ambulance Charges": "Not Covered",
      "Co - Payment": "10% after age of 80 years",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "NA",
    },
    {
      "Insurance Plan": "Super Topup Plan",
      "Insurance Company": "Niva Bupa",
      "Product Name": "Health Recharge",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes":
        "3 Years",
      "Entry Age Min & Max": "NA",
      "Unique Features": ["NA"],
      "Room Type": "Single Private AC Room",
      ICU: "Covered Actual",
      "Restore Benefit": "NA",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "60 Days/180 Days",
      "Cost Of Consumables": "Not Covered",
      "Health Check up": "Not Covered",
      "Organ Donor Expenses":
        "Covered up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Not Covered",
      "Air Ambulance Charges": "Not Covered",
      "Co - Payment": "No Co-Payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "NA",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Care Health",
      "Product Name": "Care",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes": "NA",
      "Entry Age Min & Max":
        "Child - 91 Days to 24 Years and Adult - 18 Years to Life Long",
      "Unique Features": [
        "No Claim Bonus Super",
        "Upto 50% increase for every claim free year",
        "Reduced on renewal if claim is made in previous year",
      ],
      "Room Type":
        "Single Private Room (Upgradable to next level if Single Pvt Room is not available)",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available-100% restoration for same  person same illness - Once in a policy year",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "10% of Sum Insured",
      "Pre and Post Hospitalization": "30 Days/60 Days",
      "Cost Of Consumables": "Optional at additional Premium",
      "Health Check up": "Annually",
      "Organ Donor Expenses":
        "Covered Up to Rs 1 Lakh (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Up to Sum Insured",
      "Air Ambulance Charges": "Up to Sum Insured",
      "Co - Payment": "Above 60 it's Optional",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "NA",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Care Health",
      "Product Name": "Care Advantage",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes": "NA",
      "Entry Age Min & Max":
        "Child - 91 Days to 24 Years and Adult - 18 Years to Life Long",
      "Unique Features": [
        "10% of Sum Insured for each Claim free year",
        "maximum upto 50% of Sum Insured",
        "reduced by 10% of Sum Insured in case of claim",
      ],
      "Room Type": "Any category Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available-100% restoration for same  person same illness - Once in a policy year",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": " Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "30 Days/60 Days",
      "Cost Of Consumables": "Optional at additional Premium",
      "Health Check up": "Optional",
      "Organ Donor Expenses":
        "Covered Up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Up to Sum Insured",
      "Air Ambulance Charges": "Optional",
      "Co - Payment": "Post 61 Years 20% And 70 years 30% Of Co-Payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "NA",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Care Health",
      "Product Name": "Care Freedom",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes": "NA",
      "Entry Age Min & Max":
        "Child - 91 Days to 24 Years and Adult - 18 Years to Life Long",
      "Unique Features": [
        "Companion Benefit",
        "For more than 10 consecutive days of Hospitalisation, Insured person gets:",
        "For Sum Insured 3 Lacs - Rs.10,000",
        "For Sum Insured 5/7/10 Lacs - Rs.15,000",
        "Dialysis Cover",
        "Upto Rs. 1,000 per sitting limited to 24 consecutive months",
      ],
      "Room Type":
        "For Sum Insured, 5 Lacs: Twin Sharing room, 7 & 10 Lacs: Single Private Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available-100% restoration Once in a policy year. For different ailment only",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "2 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization":
        "Upto 10% of Sum Insured covered after 3 days",
      "Pre and Post Hospitalization":
        "For Sum Insured 5, 7 & 10 Lacs: Upto 10% payable Hospitalisation expenses",
      "Cost Of Consumables": "Not Covered",
      "Health Check up": "Covered",
      "Organ Donor Expenses": "NA",
      "Road Ambulance Charges": "Upto Rs. 1,000 per Hospitalisation",
      "Air Ambulance Charges": "NA",
      "Co - Payment": "20% (Additional 10% after 70 Yrs age)",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "NA",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Niva Bupa",
      "Product Name": "Aspire",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes": "NA",
      "Entry Age Min & Max":
        "Child - 91 Days to 24 Years and Adult - 18 Years to 65 Years",
      "Unique Features": [
        "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
        "Lock the Clock (2)",
        "Pay the premium as per your entry age, till a claim is paid",
      ],
      "Room Type": "Single Private AC Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available-100% restoration for same person same illness -Unlimited Times",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "NA",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "60 days /180 Days",
      "Cost Of Consumables": "Optional at addl. Premium (Rs)",
      "Health Check up": "Covered",
      "Organ Donor Expenses":
        "Covered Up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Up to SI",
      "Air Ambulance Charges": "Up to SI",
      "Co - Payment": "Optional",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "Covered",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Niva Bupa",
      "Product Name": "Health Companion",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes": "NA",
      "Entry Age Min & Max":
        "Child - 91 Days to 24 Years and Adult - 18 Years to Life Long",
      "Unique Features": [
        "Get 20% more sum insured at renewal for each claim free year",
        "max up to 100%",
      ],
      "Room Type": "Any category Room",
      ICU: "Covered actual",
      "Restore Benefit":
        "Available-100% restoration for same person different illness -once in a policy year",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "60 days /180 Days",
      "Cost Of Consumables": "Optional at addl. Premium (Rs)",
      "Health Check up": "Covered",
      "Organ Donor Expenses":
        "Covered Up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Up to Rs  2,000 per hospitalisation",
      "Air Ambulance Charges": "Rs. 2.5 Lacs per Hospitalization",
      "Co - Payment": "No Co-payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "NA",
    },
    // tochange from here Pre Existing Disease Waiting Period
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Manipal Cigna",
      "Product Name": "Pro Health Prime",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes": "NA",
      "Entry Age Min & Max":
        "Child - 91 Days to 17 Years and Adult - 18 Years to 70 Years",
      "Unique Features": [
        "Guaranteed Cumulative Bonus of 25% per policy year, maximum up to 200% of sum insured irrespective of claim.",
      ],
      "Room Type": "Any category Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available-100% restoration for  related or unrelated illnesses - Unlimited Times",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": `• SI up to 5 Lacs - 3 Years.        
• SI above 7.5 Lacs - 2 Years.`,
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": `• SI less than 5 lacs 50%
• SI above 5 lacs up to sum insured.`,
      "Domiciliary Hospitalization": "Up to 10% of SI",
      "Pre and Post Hospitalization": "60 days /180 Days",
      "Cost Of Consumables": "Optional at addl. Premium (Rs )",
      "Health Check up": "Covered",
      "Organ Donor Expenses":
        "Covered Up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Up to SI",
      "Air Ambulance Charges": "Up to SI",
      "Co - Payment": "10% of Co-payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "Optional",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Manipal Cigna",
      "Product Name": "Prime Senior Classic",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes": "NA",
      "Entry Age Min & Max": "Adult -56 to 75 Years",
      "Unique Features": [
        "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
        "(Bonus will not reduce in case of claim)",
      ],
      "Room Type": "Single Private AC room",
      ICU: "Covered Actual",
      "Restore Benefit": "Optional at addl. Premium (Rs)",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "2 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "30 Days / 60 Days",
      "Cost Of Consumables": "Optional at addl. Premium (Rs)",
      "Health Check up": "Covered",
      "Organ Donor Expenses":
        "Covered Up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Up to SI",
      "Air Ambulance Charges": "NA",
      "Co - Payment": "Optional",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "NA",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Manipal Cigna",
      "Product Name": "Prime Senior Elite",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes": "NA",
      "Entry Age Min & Max": "Adult - 56 to 75 Years",
      "Unique Features": [
        "Guaranteed Bonus of 10% of base Sum Insured per policy year",
        "maximum up to 100% of base Sum Insured",
      ],
      "Room Type": "Single Private AC room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available-100% restoration for  related or unrelated illnesses -Unlimited Times",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "2 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "60 Days / 90 Days",
      "Cost Of Consumables": "Optional at addl. Premium (Rs)",
      "Health Check up": "Covered",
      "Organ Donor Expenses":
        "Covered Up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Up to SI",
      "Air Ambulance Charges": "Up to SI",
      "Co - Payment": "No Co-payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "NA",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Reliance",
      "Product Name": "Health Infinity",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes": "NA",
      "Entry Age Min & Max":
        "Child - 91 Days to 25 Years and Adult - 18 Years to 65 Years",
      "Unique Features": [
        "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
        "(max upto 100% of SI)",
      ],
      "Room Type": "Any category Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available-100% restoration for related or unrelated illness/injury - Once in a policy year",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "90 Days / 180 Days",
      "Cost Of Consumables": "Optional at addl. Premium (Rs)",
      "Health Check up": "Optional",
      "Organ Donor Expenses":
        "Covered Up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Covered",
      "Air Ambulance Charges": "Covered",
      "Co - Payment": "Optional",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "Covered",
    },
    {
      "Insurance Plan": "Base Plan",
      "Insurance Company": "Reliance",
      "Product Name": "Health Gain",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes": "NA",
      "Entry Age Min & Max":
        "Child - 91 Days to 25 Years and Adult - 18 Years to 65 Years",
      "Unique Features": [
        "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
        "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
      ],
      "Room Type": "Single privaite A/C Room",
      ICU: "Covered actual",
      "Restore Benefit":
        "Available-100% restoration for related or unrelated illness/injury -  Unlimited Times",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered up to 50% of SI",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "60 Days/ 60 Days",
      "Cost Of Consumables": "Optional at addl. Premium (Rs)",
      "Health Check up": "Optional",
      "Organ Donor Expenses":
        "Covered Up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Covered",
      "Air Ambulance Charges": "Covered",
      "Co - Payment": "No Co-Payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "NA",
    },
    {
      "Insurance Plan": "Super Topup Plan",
      "Insurance Company": "Manipal Cigna",
      "Product Name": "Super Top Up",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes": "NA",
      "Entry Age Min & Max":
        "Child - 91 Days to 24 Years and Adult - 18 Years to Life Long",
      "Unique Features": [
        "5% of SI per year",
        "maximum up to 50% of Sum Insured",
      ],
      "Room Type": "Any category Room",
      ICU: "Covered Actual",
      "Restore Benefit": "NA",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "4 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "NA",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "NA",
      "Pre and Post Hospitalization": "60 Days / 90 Days",
      "Cost Of Consumables": "Covered",
      "Health Check up": "NA",
      "Organ Donor Expenses":
        "Covered Up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Covered",
      "Air Ambulance Charges": "Covered",
      "Co - Payment": "No Co-payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "NA",
    },
    {
      "Insurance Plan": "Super Topup Plan",
      "Insurance Company": "ICICI LOMBARD",
      "Product Name": "Health Booster",
      "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes": "NA",
      "Entry Age Min & Max":
        "Child - 91 Days to 24 Years and Adult - 18 Years to Life Long",
      "Unique Features": [
        "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
        "(Bonus will not reduce in case of claim)",
      ],
      "Room Type": "Single privaite A/C Room",
      ICU: "Covered actual",
      "Restore Benefit":
        "we shall reset up to 100% of the Sum Insured once in a policy year in case the Sum Insured including accrued Additional Sum Insured (if any) is insufficient due to previous claims in that policy year.",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "2 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre and Post Hospitalization": "60 Days / 90 Days",
      "Cost Of Consumables": "Not Covered",
      "Health Check up": "Covered",
      "Organ Donor Expenses":
        "Covered Up to sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Covered",
      "Air Ambulance Charges": "Optional",
      "Co - Payment": "20% of the admissible claim amount above the deductible",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "NA",
      Maternity: "Not Covered",
    },

    {
      "Insurance Plan": "Super Topup Plan",
      "Insurance Company": "Care Health",
      "Product Name": "Supreme Enhance",
      "Policy Period": "1 Year",
      "Sum Insured": "90 Lacs",
      Deductible: "7 Lacs",
      "Unique Features":
        ["10% of SI per year, max up to 100% of SI; Claim will not affect Cumulative Bonus",],
      "Room Type": "Single Private A/C Room",
      ICU: "Covered Actual",
      "Restore Benefit":
        "Available - 100% restoration for unrelated or same illness - Unlimited Times",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre & Post Hospitalization": "60 Days / 90 Days",
      "Cost Of Consumables": "Not Covered",
      "Health Check up": "Not Covered",
      "Organ Donor Expenses":
        "Covered Up to Sum Insured (For the donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Not Covered",
      "Air Ambulance Charges": "Not Covered",
      "Co - Payment": "No Co-Payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "Not Covered",
      Maternity: "Not Covered",
    },

    {
      "Insurance Plan": "Super Topup Plan",
      "Insurance Company": "TATA AIG",
      "Product Name": "Medicare Plus",
      "Policy Period": "1 Year",
      "Sum Insured": "5 Lacs",
      Deductible: "2 Lacs",
      "Unique Features":
       [ "50% of Sum Insured, up to max. 100% of base Sum Insured every claim free year" ],
      "Room Type": "Covered Actual",
      ICU: "Covered Actual",
      "Restore Benefit": "No",
      "Initial Waiting Period": "30 Days (Not applicable for Accident Cases)",
      "Pre Existing Disease Waiting Period": "3 Years",
      "Specific Disease Waiting Period": "2 Years",
      "Day Care Treatment": "Covered",
      "Mental Healthcare": "Covered",
      "AYUSH Treatment": "Covered",
      "Modern Treatment": "Covered",
      "Domiciliary Hospitalization": "Covered",
      "Pre & Post Hospitalization": "60 Days / 90 Days",
      "Cost Of Consumables": "Covered",
      "Health Check up": "Up to 1% of previous Sum Insured, Max. Rs. 10,000",
      "Organ Donor Expenses":
        "Covered Up to Sum Insured (For donor pre/post expenses not covered)",
      "Road Ambulance Charges": "Rs. 3,000 per hospitalization",
      "Air Ambulance Charges": "Not covered",
      "Co - Payment": "No Co-Payment",
      "Tele- Medical Screening": "Required",
      "Life long Renewal": "Yes",
      "Animal Bite (Vaccination)": "Not Covered",
      Maternity: "Not Covered",
    },
  ];

  const tabColumns = [
    "Insurance Plan",
    "Insurance Company",
    "Product Name",
    "Policy Period",
    "Sum Insured",
    "Premium",
    "Deductible",
    "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes",
    "Unique Features",
    "Room Type",
    "ICU",
    "Restore Benefit",
    "Initial Waiting Period",
    "Pre Existing Disease Waiting Period",
    "Specific Disease Waiting Period",
    "Day Care Treatment",
    "Mental Healthcare",
    "AYUSH Treatment",
    "Modern Treatment",
    "Domiciliary Hospitalization",
    "Pre and Post Hospitalization",
    "Cost Of Consumables",
    "Health Check up",
    "Organ Donor Expenses",
    "Road Ambulance Charges",
    "Air Ambulance Charges",
    "Co - Payment",
    "Tele- Medical Screening",
    "Life long Renewal",
    "Animal Bite (Vaccination)",
    "Maternity",
    "Additional Features",
    "Additional Notes",
  ];

  const tabColumns1 = [
    "Insurance Plan",
    "Insurance Company",
    "Product Name",
    "Policy Period",
    "Sum Insured",
    "Deductible",
    "Premium",
    "Preferred Product",
  ];

  const tabColumns2 = [
    "Instant Cover - Asthama, Blood Pressure, Cholesterol, Diabetes",
    "Unique Features",
    "Room Type",
    "ICU",
    "Restore Benefit",
    "Initial Waiting Period",
    "Pre Existing Disease Waiting Period",
    "Specific Disease Waiting Period",
    "Day Care Treatment",
    "Mental Healthcare",
    "AYUSH Treatment",
    "Modern Treatment",
    "Domiciliary Hospitalization",
    "Pre and Post Hospitalization",
    "Cost Of Consumables",
    "Health Check up",
    "Organ Donor Expenses",
    "Road Ambulance Charges",
    "Air Ambulance Charges",
    "Co - Payment",
    "Tele- Medical Screening",
    "Life long Renewal",
    "Animal Bite (Vaccination)",
    "Maternity",
    "Additional Features",
    "Additional Notes",
  ];

  const premiumData = [
    {
      "Product Name": "Care Supreme",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "5 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-5 Lacs",
              "Second Year-12.5 Lacs ( 5+5+2.5 )",
              "Third year-20 Lacs (12.5+5+2.5)",
              "Fourth Year-25 Lacs (20+5)",
              "Fifth Year-30 Lacs (25+5)",
              "Sixth Year Onwards 35 Lacs (30+5)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "7 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-7 Lacs",
              "Second Year-17.5 Lacs (7+7+3.5)",
              "Third year-28 Lacs (17.5+7+3.5)",
              "Fourth Year-35 Lacs (28+7)",
              "Fifth Year-42 Lacs (35+7)",
              "Sixth Year onwards-49 Lacs (42+7)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "10 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-10 Lacs",
              "Second Year-25 Lacs (10+10+5)",
              "Third year-40 Lacs (25+10+5)",
              "Fourth Year-50 Lacs (40+10)",
              "Fifth Year-60 Lacs (50+10)",
              "Sixth Year Onwards 70 Lacs (60+10)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "15 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-15 Lacs",
              "Second Year-37.5 Lacs (15+15+7.5)",
              "Third year-60 Lacs (37.5+15+7.5)",
              "Fourth Year-75 Lacs (60+15)",
              "Fifth Year-90 Lacs (75+15)",
              "Sixth Year Onwards 105 Lacs (90+15)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "25 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-25 Lacs",
              "Second Year-62.5 Lacs (25+25+12.5)",
              "Third year-100Lacs (62.5+25+12.5)",
              "Fourth Year-125 Lacs (100+25)",
              "Fifth Year-150 Lacs (125+25)",
              "Sixth Year Onwards 175 Lacs (150+25)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "50 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-50 Lacs",
              "Second Year-125 Lacs (50+50+25)",
              "Third year-200Lacs (125+50+25)",
              "Fourth Year-250Lacs (200+50)",
              "Fifth Year-300Lacs (250+50) ",
              "Sixth Year Onwards 350 Lacs (300+50)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "100 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-100 Lacs",
              "Second Year-250 Lacs (100+100+50)",
              "Third year-400 Lacs (250+100+50)",
              "Fourth Year-500 Lacs (400+100)",
              "Fifth Year-600Lacs (500+100)",
              "Sixth Year Onwards 700 Lacs (600+100)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "5 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-5 Lacs",
              "Second Year-12.5 Lacs ( 5+5+2.5 )",
              "Third year-20 Lacs (12.5+5+2.5)",
              "Fourth Year-25 Lacs (20+5)",
              "Fifth Year-30 Lacs (25+5)",
              "Sixth Year Onwards 35 Lacs (30+5)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "7 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-7 Lacs",
              "Second Year-17.5 Lacs (7+7+3.5)",
              "Third year-28 Lacs (17.5+7+3.5)",
              "Fourth Year-35 Lacs (28+7)",
              "Fifth Year-42 Lacs (35+7)",
              "Sixth Year onwards-49 Lacs (42+7)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "10 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-10 Lacs",
              "Second Year-25 Lacs (10+10+5)",
              "Third year-40 Lacs (25+10+5)",
              "Fourth Year-50 Lacs (40+10)",
              "Fifth Year-60 Lacs (50+10)",
              "Sixth Year Onwards 70 Lacs (60+10)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "15 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-15 Lacs",
              "Second Year-37.5 Lacs (15+15+7.5)",
              "Third year-60 Lacs (37.5+15+7.5)",
              "Fourth Year-75 Lacs (60+15)",
              "Fifth Year-90 Lacs (75+15)",
              "Sixth Year Onwards 105 Lacs (90+15)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "25 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-25 Lacs",
              "Second Year-62.5 Lacs (25+25+12.5)",
              "Third year-100Lacs (62.5+25+12.5)",
              "Fourth Year-125 Lacs (100+25)",
              "Fifth Year-150 Lacs (125+25)",
              "Sixth Year Onwards 175 Lacs (150+25)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "50 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-50 Lacs",
              "Second Year-125 Lacs (50+50+25)",
              "Third year-200Lacs (125+50+25)",
              "Fourth Year-250Lacs (200+50)",
              "Fifth Year-300Lacs (250+50) ",
              "Sixth Year Onwards 350 Lacs (300+50)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "100 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-100 Lacs",
              "Second Year-250 Lacs (100+100+50)",
              "Third year-400 Lacs (250+100+50)",
              "Fourth Year-500 Lacs (400+100)",
              "Fifth Year-600Lacs (500+100)",
              "Sixth Year Onwards 700 Lacs (600+100)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "5 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-5 Lacs",
              "Second Year-12.5 Lacs ( 5+5+2.5 )",
              "Third year-20 Lacs (12.5+5+2.5)",
              "Fourth Year-25 Lacs (20+5)",
              "Fifth Year-30 Lacs (25+5)",
              "Sixth Year Onwards 35 Lacs (30+5)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "7 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-7 Lacs",
              "Second Year-17.5 Lacs (7+7+3.5)",
              "Third year-28 Lacs (17.5+7+3.5)",
              "Fourth Year-35 Lacs (28+7)",
              "Fifth Year-42 Lacs (35+7)",
              "Sixth Year onwards-49 Lacs (42+7)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "10 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-10 Lacs",
              "Second Year-25 Lacs (10+10+5)",
              "Third year-40 Lacs (25+10+5)",
              "Fourth Year-50 Lacs (40+10)",
              "Fifth Year-60 Lacs (50+10)",
              "Sixth Year Onwards 70 Lacs (60+10)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "15 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-15 Lacs",
              "Second Year-37.5 Lacs (15+15+7.5)",
              "Third year-60 Lacs (37.5+15+7.5)",
              "Fourth Year-75 Lacs (60+15)",
              "Fifth Year-90 Lacs (75+15)",
              "Sixth Year Onwards 105 Lacs (90+15)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "25 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-25 Lacs",
              "Second Year-62.5 Lacs (25+25+12.5)",
              "Third year-100Lacs (62.5+25+12.5)",
              "Fourth Year-125 Lacs (100+25)",
              "Fifth Year-150 Lacs (125+25)",
              "Sixth Year Onwards 175 Lacs (150+25)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "50 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-50 Lacs",
              "Second Year-125 Lacs (50+50+25)",
              "Third year-200Lacs (125+50+25)",
              "Fourth Year-250Lacs (200+50)",
              "Fifth Year-300Lacs (250+50) ",
              "Sixth Year Onwards 350 Lacs (300+50)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
            "100 Lacs": [
              "7X Cover (Inc. of CB)",
              "First Year-100 Lacs",
              "Second Year-250 Lacs (100+100+50)",
              "Third year-400 Lacs (250+100+50)",
              "Fourth Year-500 Lacs (400+100)",
              "Fifth Year-600Lacs (500+100)",
              "Sixth Year Onwards 700 Lacs (600+100)",
              "In five years SI becomes 7 Times",
              "(Irrespective of claim/No claim)",
            ],
          },
        },
      },
      Deductible: ["0"],
    },
    {
      "Product Name": "Care Supreme Senior",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "5 Lacs": [],
            "7 Lacs": [
              ],
            "10 Lacs": [
             
            ],
            "15 Lacs": [
          
            ],
            "25 Lacs": [

            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "5 Lacs": [
            ],
            "7 Lacs": [

            ],
            "10 Lacs": [

            ],
            "15 Lacs": [

            ],
            "25 Lacs": [

            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "5 Lacs": [

            ],
            "7 Lacs": [

            ],
            "10 Lacs": [

            ],
            "15 Lacs": [
            ],
            "25 Lacs": [
            ],
          },
        },
      },
      Deductible: ["0"],
    },
    {
      "Product Name": "Care Heart",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "3 Lacs": [],
            "5 Lacs": [],
            "7 Lacs": [
              ],
            "10 Lacs": [
             
            ]
          },
        },
        "2 Years": {
          "Premium Data": {
            "3 Lacs": [],
            "5 Lacs": [
            ],
            "7 Lacs": [

            ],
            "10 Lacs": [

            ]
          },
        },
        "3 Years": {
          "Premium Data": {
            "3 Lacs": [],
            "5 Lacs": [

            ],
            "7 Lacs": [

            ],
            "10 Lacs": [

            ]
          },
        },
      },
      Deductible: ["0"],
    },
    {
      "Product Name": "Supreme Enhance",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "45 Lacs": [],
            "85 Lacs": [],
            "90 Lacs": [
              ],
            "93 Lacs": [
             
            ],
            "95 Lacs": [
             
            ]
          },
        },
        "2 Years": {
          "Premium Data": {
            "45 Lacs": [],
            "85 Lacs": [],
            "90 Lacs": [
              ],
            "93 Lacs": [
             
            ],
            "95 Lacs": [
             
            ]
          },
        },
        "3 Years": {
          "Premium Data": {
            "45 Lacs": [],
            "85 Lacs": [],
            "90 Lacs": [
              ],
            "93 Lacs": [
             
            ],
            "95 Lacs": [
             
            ]
          },
        },
      },
      Deductible: ["0", "7", "10", "15"],
    },
    {
      "Product Name": "Optima Secure",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "5 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year-10 Lacs (5+5)",
              "Second Year-12.5 Lacs (10+2.5)",
              "Third year onwards-15 Lacs (12.5+2.5)",
              "(Irrespective of claim/No claim)",
            ],
            "10 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year-20 Lacs (10+10)",
              "Second Year-25 Lacs (20+5)",
              "Third year onwards-30 Lacs (25+5)",
              "(Irrespective of claim/No claim)",
            ],
            "15 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year-30 Lacs (15+15)",
              "Second Year-37.5 Lacs (30+7.5)",
              "Third year onwards-45 Lacs (37.5+7.5)",
              "(Irrespective of claim/No claim)",
            ],
            "20 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year-40 Lacs (20+20)",
              "Second Year-50 Lacs (40+10)",
              "Third year onwards-60 Lacs (50+10)",
              "(Irrespective of claim/No claim)",
            ],
            "25 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year-50 Lacs (25+25)",
              "Second Year-62.5 Lacs (50+12.5)",
              "Third year onwards-75 Lacs (62.5+12.5)",
              "(Irrespective of claim/No claim)",
            ],
            "50 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year-100 Lacs (50+50)",
              "Second Year-125 Lacs (100+25)",
              "Third year onwards-150 Lacs (125+25)",
              "(Irrespective of claim/No claim)",
            ],
            "100 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year-200 Lacs (100+100)",
              "Second Year-250 Lacs (200+50)",
              "Third year onwards-300 Lacs (250+50)",
              "(Irrespective of claim/No claim)",
            ],
            "200 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year- 400 Lacs (200+200)",
              "Second Year- 500 Lacs (400+100)",
              "Third year onwards- 600 Lacs (500+100)",
              "(Irrespective of claim/No claim)",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "5 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year-10 Lacs (5+5)",
              "Second Year-12.5 Lacs (10+2.5)",
              "Third year onwards-15 Lacs (12.5+2.5)",
              "(Irrespective of claim/No claim)",
            ],
            "10 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year-20 Lacs (10+10)",
              "Second Year-25 Lacs (20+5)",
              "Third year onwards-30 Lacs (25+5)",
              "(Irrespective of claim/No claim)",
            ],
            "15 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year-30 Lacs (15+15)",
              "Second Year-37.5 Lacs (30+7.5)",
              "Third year onwards-45 Lacs (37.5+7.5)",
              "(Irrespective of claim/No claim)",
            ],
            "20 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year-40 Lacs (20+20)",
              "Second Year-50 Lacs (40+10)",
              "Third year onwards-60 Lacs (50+10)",
              "(Irrespective of claim/No claim)",
            ],
            "25 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year-50 Lacs (25+25)",
              "Second Year-62.5 Lacs (50+12.5)",
              "Third year onwards-75 Lacs (62.5+12.5)",
              "(Irrespective of claim/No claim)",
            ],
            "50 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year-100 Lacs (50+50)",
              "Second Year-125 Lacs (100+25)",
              "Third year onwards-150 Lacs (125+25)",
              "(Irrespective of claim/No claim)",
            ],
            "100 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year-200 Lacs (100+100)",
              "Second Year-250 Lacs (200+50)",
              "Third year onwards-300 Lacs (250+50)",
              "(Irrespective of claim/No claim)",
            ],
            "200 Lacs": [
              "3X Cover (Inc. of Cu. Bonus)",
              "First Year- 400 Lacs (200+200)",
              "Second Year- 500 Lacs (400+100)",
              "Third year onwards- 600 Lacs (500+100)",
              "(Irrespective of claim/No claim)",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "5 Lacs": [
              "4X Cover (Inc. of Cu. Bonus)",
              "First Year-15 Lacs (5+5+5)",
              "Second Year- 17.5 Lacs (15+2.5)",
              "Third year Onwards- 20 Lacs (17.5+2.5)",
              "(Irrespective of claim/No claim)",
            ],
            "10 Lacs": [
              "4X Cover (Inc. of Cu. Bonus)",
              "First Year-30 Lacs (10+10 +10)",
              "Second Year- 35 Lacs (30+5)",
              "Third year Onwards 40 Lacs (35+5)",
              "(Irrespective of claim/No claim)",
            ],
            "15 Lacs": [
              "4X Cover (Inc. of Cu. Bonus)",
              "First Year-45 Lacs (15+15+15)",
              "Second Year- 52.5 Lacs (45+7.5)",
              "Third year Onwards 60 Lacs (52.5+7.5)",
              "(Irrespective of claim/No claim)",
            ],
            "20 Lacs": [
              "4X Cover (Inc. of Cu. Bonus)",
              "First Year-60 Lacs (20+20+20)",
              "Second Year- 70 Lacs (60+10)",
              "Third year Onwards 80 Lacs (70+10)",
              "(Irrespective of claim/No claim)",
            ],
            "25 Lacs": [
              "4X Cover (Inc. of Cu. Bonus)",
              "First Year-75 Lacs (25+25+25)",
              "Second Year- 87.5 Lacs (75+12.5)",
              "Third year Onwards 1 Crore (87.5 +12.5)",
              "(Irrespective of claim/No claim)",
            ],
            "50 Lacs": [
              "4X Cover (Inc. of Cu. Bonus)",
              "First Year-1.5 Crore (50+50+50)",
              "Second Year- 1.75 Crore (1.5 Cr+25 Lacs)",
              "Third year Onwards 2 Crore (1.75 Cr+25 Lacs)",
              "(Irrespective of claim/No claim)",
            ],
            "100 Lacs": [
              "4X Cover (Inc. of Cu. Bonus)",
              "First Year-3 Crore (1Cr+1Cr+1Cr)",
              "Second Year- 3.50 Crore (3 Cr+50 Lacs)",
              "Third year Onwards 4 Crore (3.50 Cr+50 Lacs)",
              "(Irrespective of claim/No claim)",
            ],
            "200 Lacs": [
              "4X Cover (Inc. of Cu. Bonus)",
              "First Year-6 Crore (2Cr+2Cr+2Cr)",
              "Second Year- 7 Crore (6 Cr+1 Cr)",
              "Third year Onwards 8 Crore (7 Cr+1 Cr)",
              "(Irrespective of claim/No claim)",
            ],
          },
        },
      },
      Deductible: ["0", "25K", "50K", "1 Lac", "2 Lacs", "3 Lacs", "5 Lacs"],
    },
    {
      "Product Name": "ReAssure 2.0",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "5 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "7.5 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "10 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "15 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "20 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "25 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "50 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "100 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "5 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "7.5 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "10 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "15 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "20 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "25 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "50 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "100 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "5 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "7.5 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "10 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "15 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "20 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "25 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "50 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
            "100 Lacs": [
              "5X Cover.",
              "Unutilised Base Sum Insured carried forward to the next policy year, maximum up to 5 times of Base Sum Insured.",
              "Lock the Clock",
              "Your age is locked at entry when you buy the policy, till you make a claim",
            ],
          },
        },
      },
      Deductible: ["0", "20K", "30K", "50K", "1 Lac"],
    },
    {
      "Product Name": "Senior First (Platinum)",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "5 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
            "10 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
            "15 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
            "20 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
            "25 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "5 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
            "10 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
            "15 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
            "20 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
            "25 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "5 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
            "10 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
            "15 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
            "20 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
            "25 Lacs": [
              "10% of Sum Insured for every claim free year maximum upto 100% of Sum Insured.",
              "It is not reduced even if there is a claim.",
            ],
          },
        },
      },
      Deductible: ["0", "1 Lac", "2 Lacs", "3 lacs", "4 Lacs", "5 Lacs"],
    },
    {
      "Product Name": "Health Advantedge",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "5 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "7.5 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "10 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "15 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "20 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "25 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "30 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "40 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "50 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "75 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "100 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "150 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "200 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "300 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "5 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "7.5 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "10 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "15 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "20 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "25 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "30 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "40 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "50 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "75 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "100 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "150 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "200 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "300 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "5 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "7.5 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "10 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "15 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "20 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "25 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "30 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "40 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "50 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "75 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "100 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "150 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "200 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
            "300 Lacs": [
              "20% of SI maximum upto 100%.",
              "(Irrespective of claim/No claim).",
            ],
          },
        },
      },
      Deductible: ["0"],
    },
    {
      "Product Name": "Health Recharge",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "7.5 Lacs": ["NA"],
            "10 Lacs": ["NA"],
            "15 Lacs": ["NA"],
            "25 Lacs": ["NA"],
            "40 Lacs": ["NA"],
            "45 Lacs": ["NA"],
            "65 Lacs": ["NA"],
            "70 Lacs": ["NA"],
            "90 Lacs": ["NA"],
            "95 Lacs": ["NA"],
          },
        },
        "2 Years": {
          "Premium Data": {
            "7.5 Lacs": ["NA"],
            "10 Lacs": ["NA"],
            "15 Lacs": ["NA"],
            "25 Lacs": ["NA"],
            "40 Lacs": ["NA"],
            "45 Lacs": ["NA"],
            "65 Lacs": ["NA"],
            "70 Lacs": ["NA"],
            "90 Lacs": ["NA"],
            "95 Lacs": ["NA"],
          },
        },
        "3 Years": {
          "Premium Data": {
            "7.5 Lacs": ["NA"],
            "10 Lacs": ["NA"],
            "15 Lacs": ["NA"],
            "25 Lacs": ["NA"],
            "40 Lacs": ["NA"],
            "45 Lacs": ["NA"],
            "65 Lacs": ["NA"],
            "70 Lacs": ["NA"],
            "90 Lacs": ["NA"],
            "95 Lacs": ["NA"],
          },
        },
      },
      Deductible: ["0", "3 lacs", "4 Lacs", "5 Lacs", "10 Lacs"],
    },
    {
      "Product Name": "Medisure",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "3 Lacs": ["NA"],
            "5 Lacs": ["NA"],
            "6 Lacs": ["NA"],
            "7 Lacs": ["NA"],
            "8 Lacs": ["NA"],
            "10 Lacs": ["NA"],
            "11 Lacs": ["NA"],
            "12 Lacs": ["NA"],
            "15 Lacs": ["NA"],
            "16 Lacs": ["NA"],
            "20 Lacs": ["NA"],
          },
        },
        "2 Years": {
          "Premium Data": {
            "3 Lacs": ["NA"],
            "5 Lacs": ["NA"],
            "6 Lacs": ["NA"],
            "7 Lacs": ["NA"],
            "8 Lacs": ["NA"],
            "10 Lacs": ["NA"],
            "11 Lacs": ["NA"],
            "12 Lacs": ["NA"],
            "15 Lacs": ["NA"],
            "16 Lacs": ["NA"],
            "20 Lacs": ["NA"],
          },
        },
      },
      Deductible: ["0", "2 lacs", "3 Lacs", "4 Lacs", "5 Lacs"],
    },
    {
      "Product Name": "Care",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "5 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "7 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "10 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "15 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "20 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "25 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "30 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "40 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "50 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "60 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "75 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "5 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "7 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "10 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "15 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "20 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "25 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "30 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "40 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "50 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "60 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "75 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "5 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "7 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "10 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "15 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "20 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "25 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "30 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "40 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "50 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "60 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
            "75 Lacs": [
              "No Claim Bonus Super",
              "Upto 50% increase for every claim free year",
              "Reduced on renewal if claim is made in previous year",
            ],
          },
        },
      },
      Deductible: ["0"],
    },
    {
      "Product Name": "Care Advantage",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "25 Lacs": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "50 Lacs": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "1 Crore": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "2 Crores": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "3 Crores": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "6 Crores": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "25 Lacs": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "50 Lacs": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "1 Crore": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "2 Crores": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "3 Crores": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "6 Crores": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "25 Lacs": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "50 Lacs": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "1 Crore": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "2 Crores": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "3 Crores": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
            "6 Crores": [
              "10% of Sum Insured for each Claim free year",
              "maximum upto 50% of Sum Insured",
              "reduced by 10% of Sum Insured in case of claim",
            ],
          },
        },
      },
      Deductible: ["0"],
    },
    {
      "Product Name": "Care Freedom",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "3 Lacs": [
              "Companion Benefit",
              "For more than 10 consecutive days of Hospitalisation, Insured person gets:",
              "For Sum Insured 3 Lacs - Rs.10,000",
              "For Sum Insured 5/7/10 Lacs - Rs.15,000",
              "Dialysis Cover",
              "Upto Rs. 1,000 per sitting limited to 24 consecutive months",
            ],
            "5 Lacs": [
              "Companion Benefit",
              "For more than 10 consecutive days of Hospitalisation, Insured person gets:",
              "For Sum Insured 3 Lacs - Rs.10,000",
              "For Sum Insured 5/7/10 Lacs - Rs.15,000",
              "Dialysis Cover",
              "Upto Rs. 1,000 per sitting limited to 24 consecutive months",
            ],
            "7 Lacs": [
              "Companion Benefit",
              "For more than 10 consecutive days of Hospitalisation, Insured person gets:",
              "For Sum Insured 3 Lacs - Rs.10,000",
              "For Sum Insured 5/7/10 Lacs - Rs.15,000",
              "Dialysis Cover",
              "Upto Rs. 1,000 per sitting limited to 24 consecutive months",
            ],
            "10 Lacs": [
              "Companion Benefit",
              "For more than 10 consecutive days of Hospitalisation, Insured person gets:",
              "For Sum Insured 3 Lacs - Rs.10,000",
              "For Sum Insured 5/7/10 Lacs - Rs.15,000",
              "Dialysis Cover",
              "Upto Rs. 1,000 per sitting limited to 24 consecutive months",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "3 Lacs": [
              "Companion Benefit",
              "For more than 10 consecutive days of Hospitalisation, Insured person gets:",
              "For Sum Insured 3 Lacs - Rs.10,000",
              "For Sum Insured 5/7/10 Lacs - Rs.15,000",
              "Dialysis Cover",
              "Upto Rs. 1,000 per sitting limited to 24 consecutive months",
            ],
            "5 Lacs": [
              "Companion Benefit",
              "For more than 10 consecutive days of Hospitalisation, Insured person gets:",
              "For Sum Insured 3 Lacs - Rs.10,000",
              "For Sum Insured 5/7/10 Lacs - Rs.15,000",
              "Dialysis Cover",
              "Upto Rs. 1,000 per sitting limited to 24 consecutive months",
            ],
            "7 Lacs": [
              "Companion Benefit",
              "For more than 10 consecutive days of Hospitalisation, Insured person gets:",
              "For Sum Insured 3 Lacs - Rs.10,000",
              "For Sum Insured 5/7/10 Lacs - Rs.15,000",
              "Dialysis Cover",
              "Upto Rs. 1,000 per sitting limited to 24 consecutive months",
            ],
            "10 Lacs": [
              "Companion Benefit",
              "For more than 10 consecutive days of Hospitalisation, Insured person gets:",
              "For Sum Insured 3 Lacs - Rs.10,000",
              "For Sum Insured 5/7/10 Lacs - Rs.15,000",
              "Dialysis Cover",
              "Upto Rs. 1,000 per sitting limited to 24 consecutive months",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "3 Lacs": [
              "Companion Benefit",
              "For more than 10 consecutive days of Hospitalisation, Insured person gets:",
              "For Sum Insured 3 Lacs - Rs.10,000",
              "For Sum Insured 5/7/10 Lacs - Rs.15,000",
              "Dialysis Cover",
              "Upto Rs. 1,000 per sitting limited to 24 consecutive months",
            ],
            "5 Lacs": [
              "Companion Benefit",
              "For more than 10 consecutive days of Hospitalisation, Insured person gets:",
              "For Sum Insured 3 Lacs - Rs.10,000",
              "For Sum Insured 5/7/10 Lacs - Rs.15,000",
              "Dialysis Cover",
              "Upto Rs. 1,000 per sitting limited to 24 consecutive months",
            ],
            "7 Lacs": [
              "Companion Benefit",
              "For more than 10 consecutive days of Hospitalisation, Insured person gets:",
              "For Sum Insured 3 Lacs - Rs.10,000",
              "For Sum Insured 5/7/10 Lacs - Rs.15,000",
              "Dialysis Cover",
              "Upto Rs. 1,000 per sitting limited to 24 consecutive months",
            ],
            "10 Lacs": [
              "Companion Benefit",
              "For more than 10 consecutive days of Hospitalisation, Insured person gets:",
              "For Sum Insured 3 Lacs - Rs.10,000",
              "For Sum Insured 5/7/10 Lacs - Rs.15,000",
              "Dialysis Cover",
              "Upto Rs. 1,000 per sitting limited to 24 consecutive months",
            ],
          },
        },
      },
      Deductible: ["0"],
    },
    {
      "Product Name": "Aspire",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "3 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "5 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "7.5 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "10 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "15 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "20 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "25 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "50 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "1 Crore": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "3 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "5 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "7.5 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "10 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "15 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "20 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "25 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "50 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "1 Crore": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "3 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "5 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "7.5 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "10 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "15 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "20 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "25 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "50 Lacs": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
            "1 Crore": [
              "Unutilised Base sum insured carries forward to the next policy year, as per your entry age.",
              "Lock the Clock (2)",
              "Pay the premium as per your entry age, till a claim is paid",
            ],
          },
        },
      },
      Deductible: ["0", "20K", "30K", "50K", "1 Lac"],
    },
    {
      "Product Name": "Health Companion",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "3 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "4 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "5 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "7.5 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "10 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "12.5 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "15 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "20 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "30 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "50 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "1 Crore": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "3 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "4 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "5 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "7.5 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "10 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "12.5 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "15 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "20 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "30 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "50 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "1 Crore": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "3 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "4 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "5 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "7.5 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "10 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "12.5 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "15 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "20 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "30 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "50 Lacs": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
            "1 Crore": [
              "Get 20% more sum insured at renewal for each claim free year",
              "max up to 100%",
            ],
          },
        },
      },
      Deductible: [
        "0",
        "1 lac",
        "2 lacs",
        "3 lacs",
        "4 Lacs",
        "5 Lacs",
        "10 Lacs",
      ],
    },
    {
      "Product Name": "Pro Health Prime",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "3 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "4 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "5 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "7.5 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "10 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "12.5 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "15 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "20 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "25 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "30 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "40 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "50 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "1 Crore": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "3 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "4 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "5 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "7.5 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "10 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "12.5 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "15 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "20 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "25 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "30 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "40 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "50 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "1 Crore": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "3 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "4 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "5 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "7.5 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "10 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "12.5 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "15 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "20 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "25 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "30 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "40 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "50 Lacs": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
            "1 Crore": [
              "Guaranteed Cumulative",
              "Bonus of 25% per policy year, maximum up to 200% of Sum Insured",
              "irrespective of claim",
            ],
          },
        },
      },
      Deductible: ["0", "10K", "25K"],
    },
    {
      "Product Name": "Prime Senior Classic",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "3 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "5 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "7.5 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "10 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "15 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "20 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "25 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "50 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "3 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "5 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "7.5 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "10 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "15 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "20 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "25 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "50 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "3 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "5 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "7.5 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "10 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "15 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "20 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "25 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "50 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 100% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
          },
        },
      },
      Deductible: ["0"],
    },
    {
      "Product Name": "Prime Senior Elite",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "5 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "7.5 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "10 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "15 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "20 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "25 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "50 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "5 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "7.5 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "10 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "15 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "20 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "25 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "50 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "5 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "7.5 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "10 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "15 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "20 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "25 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
            "50 Lacs": [
              "Guaranteed Bonus of 10% of base Sum Insured per policy year",
              "maximum up to 100% of base Sum Insured",
            ],
          },
        },
      },
      Deductible: ["0"],
    },
    {
      "Product Name": "Super Top Up",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "3 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "4 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "5 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "6 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "8 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "10 Lacs": [
              "5% of SI per year",
              "maximum up to 50% of Sum Insured",
            ],
            "15 Lacs": [
              "5% of SI per year",
              "maximum up to 50% of Sum Insured",
            ],
            "20 Lacs": [
              "5% of SI per year",
              "maximum up to 50% of Sum Insured",
            ],
            "30 Lacs": [
              "5% of SI per year",
              "maximum up to 50% of Sum Insured",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "3 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "4 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "5 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "6 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "8 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "10 Lacs": [
              "5% of SI per year",
              "maximum up to 50% of Sum Insured",
            ],
            "15 Lacs": [
              "5% of SI per year",
              "maximum up to 50% of Sum Insured",
            ],
            "20 Lacs": [
              "5% of SI per year",
              "maximum up to 50% of Sum Insured",
            ],
            "30 Lacs": [
              "5% of SI per year",
              "maximum up to 50% of Sum Insured",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "3 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "4 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "5 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "6 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "8 Lacs": ["5% of SI per year", "maximum up to 50% of Sum Insured"],
            "10 Lacs": [
              "5% of SI per year",
              "maximum up to 50% of Sum Insured",
            ],
            "15 Lacs": [
              "5% of SI per year",
              "maximum up to 50% of Sum Insured",
            ],
            "20 Lacs": [
              "5% of SI per year",
              "maximum up to 50% of Sum Insured",
            ],
            "30 Lacs": [
              "5% of SI per year",
              "maximum up to 50% of Sum Insured",
            ],
          },
        },
      },
      Deductible: [
        "0",
        "3 lacs",
        "3.5 lacs",
        "4 Lacs",
        "4.5 lacs",
        "5 Lacs",
        "5.5 lacs",
        "7.5 lacs",
        "10 Lacs",
      ],
    },
    {
      "Product Name": "Health Infinity",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "5 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "10 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "15 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "25 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "50 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "1 Crore": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "2 Crores": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "3 Crores": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "4 Crores": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "5 Crores": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "5 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "10 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "15 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "25 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "50 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "1 Crore": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "2 Crores": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "3 Crores": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "4 Crores": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "5 Crores": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "5 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "10 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "15 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "25 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "50 Lacs": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "1 Crore": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "2 Crores": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "3 Crores": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "4 Crores": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
            "5 Crores": [
              "Additional SI of either 20% or 33.33% of SI (as opted) at the end of each policy year.",
              "(max upto 100% of SI)",
            ],
          },
        },
      },
      Deductible: ["0"],
    },
    {
      "Product Name": "Health Gain",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "3 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "5 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "10 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "15 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "20 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "25 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "30 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "50 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "1 Crore": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "3 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "5 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "10 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "15 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "20 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "25 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "30 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "50 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "1 Crore": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "3 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "5 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "10 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "15 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "20 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "25 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "30 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "50 Lacs": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
            "1 Crore": [
              "Increase in base sum-insured by 33.33% for every claim free policy year, subject to a maximum of 100% of base sum-insured and decrease by 33.33 % of base sum-insured for every claim year.",
              "Additional, 20% of Base sum insured on same claim, in single Hospitalisation",
            ],
          },
        },
      },
      Deductible: ["0", "10K", "25K", "50K", "1 Lac"],
    },
    {
      "Product Name": "Health Booster",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "5 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "10 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "15 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "20 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "25 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "30 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "40 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "50 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
          },
        },
        "2 Years": {
          "Premium Data": {
            "5 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "10 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "15 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "20 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "25 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "30 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "40 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "50 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
          },
        },
        "3 Years": {
          "Premium Data": {
            "5 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "10 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "15 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "20 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "25 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "30 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "40 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
            "50 Lacs": [
              "10% of base Sum Insured for every claim free year maximum up to 50% of base Sum Insured",
              "(Bonus will not reduce in case of claim)",
            ],
          },
        },
      },
      Deductible: ["0", "3 lacs", "4 Lacs", "5 Lacs"],
    },

    {
      "Product Name": "Elevate",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "5 Lacs": [],
            "7.5 Lacs": [],
            "10 Lacs": [],
            "15 Lacs": [],
            "20 Lacs": [],
            "25 Lacs": [],
            "50 Lacs": [],
            "1 Crore": [],
            "3 Crore": []
          },
        },
        "2 Years": {
          "Premium Data": {
            "5 Lacs": [],
            "7.5 Lacs": [],
            "10 Lacs": [],
            "15 Lacs": [],
            "20 Lacs": [],
            "25 Lacs": [],
            "50 Lacs": [],
            "1 Crore": [],
            "3 Crore": []
          },
        },
        "3 Years": {
          "Premium Data": {
            "5 Lacs": [],
            "7.5 Lacs": [],
            "10 Lacs": [],
            "15 Lacs": [],
            "20 Lacs": [],
            "25 Lacs": [],
            "50 Lacs": [],
            "1 Crore": [],
            "3 Crore": []
          },
        },
      },
      Deductible: ["0", "10%", "20%", "30%", "40%", "50%"],
    },
    {
      "Product Name": "Medicare",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "5 Lacs": [],
            "10 Lacs": [],
            "15 Lacs": [],
            "20 Lacs": [],
            "25 Lacs": [],
            "50 Lacs": [],
            "75 Lacs": [],
            "1 Crore": [],
            "2 Crore": [],
            "3 Crore": []
          },
        },
        "2 Years": {
          "Premium Data": {
            "Premium Data": {
              "5 Lacs": [],
              "10 Lacs": [],
              "15 Lacs": [],
              "20 Lacs": [],
              "25 Lacs": [],
              "50 Lacs": [],
              "75 Lacs": [],
              "1 Crore": [],
              "2 Crore": [],
              "3 Crore": []
            },
          },
        },
        "3 Years": {
          "Premium Data": {
            "Premium Data": {
              "5 Lacs": [],
              "10 Lacs": [],
              "15 Lacs": [],
              "20 Lacs": [],
              "25 Lacs": [],
              "50 Lacs": [],
              "75 Lacs": [],
              "1 Crore": [],
              "2 Crore": [],
              "3 Crore": []
            },
          },
        },
      },
      Deductible: ["0"],
    },
    {
      "Product Name": "Medicare Plus",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "3 Lacs": [],
            "5 Lacs": [],
            "10 Lacs": [],
            "15 Lacs": [],
            "20 Lacs": [],
            "25 Lacs": [],
            "50 Lacs": [],
            "1 Crore": [],
          },
        },
        "2 Years": {
          "Premium Data": {
            "Premium Data": {
              "3 Lacs": [],
              "5 Lacs": [],
              "10 Lacs": [],
              "15 Lacs": [],
              "20 Lacs": [],
              "25 Lacs": [],
              "50 Lacs": [],
              "1 Crore": [],
            },
          },
        },
        "3 Years": {
          "Premium Data": {
            "Premium Data": {
              "3 Lacs": [],
              "5 Lacs": [],
              "10 Lacs": [],
              "15 Lacs": [],
              "20 Lacs": [],
              "25 Lacs": [],
              "50 Lacs": [],
              "1 Crore": [],
            },
          },
        },
      },
      Deductible: ["0", "2 Lacs", "3 Lacs", "5 Lacs", "10 Lacs", "15 Lacs", "20 Lacs"],
    },
    {
      "Product Name": "Energy",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "2 Lacs": [],
            "3 Lacs": [],
            "5 Lacs": [],
            "10 Lacs": [],
            "20 Lacs": [],
            "25 Lacs": [],
            "50 Lacs": [],
          },
        },
        "2 Years": {
          "Premium Data": {
            "Premium Data": {
              "2 Lacs": [],
              "3 Lacs": [],
              "5 Lacs": [],
              "10 Lacs": [],
              "20 Lacs": [],
              "25 Lacs": [],
              "50 Lacs": [],
            },
          },
        },
        "3 Years": {
          "Premium Data": {
            "Premium Data": {
              "2 Lacs": [],
              "3 Lacs": [],
              "5 Lacs": [],
              "10 Lacs": [],
              "20 Lacs": [],
              "25 Lacs": [],
              "50 Lacs": [],
            },
          },
        },
      },
      Deductible: ["0"],
    },
    {
      "Product Name": "Activate Booster",
      "Policy Period": {
        "1 Year": {
          "Premium Data": {
            "10 Lacs": [],
            "15 Lacs": [],
            "20 Lacs": [],
            "25 Lacs": [],
            "45 Lacs": [],
            "50 Lacs": [],
            "85 Lacs": [],
            "90 Lacs": [],
            "95 Lacs": [],
            "1 Crore": [],
            "3 Crore": [],
            "4.9 Crore": [],
          },
        },
        "2 Years": {
          "Premium Data": {
            "Premium Data": {
              "10 Lacs": [],
              "15 Lacs": [],
              "20 Lacs": [],
              "25 Lacs": [],
              "45 Lacs": [],
              "50 Lacs": [],
              "85 Lacs": [],
              "90 Lacs": [],
              "95 Lacs": [],
              "1 Crore": [],
              "3 Crore": [],
              "4.9 Crore": [],
            },
          },
        },
        "3 Years": {
          "Premium Data": {
            "Premium Data": {
              "10 Lacs": [],
            "15 Lacs": [],
            "20 Lacs": [],
            "25 Lacs": [],
            "45 Lacs": [],
            "50 Lacs": [],
            "85 Lacs": [],
            "90 Lacs": [],
            "95 Lacs": [],
            "1 Crore": [],
            "3 Crore": [],
            "4.9 Crore": [],
            },
          },
        },
      },
      Deductible: ["3 Lacs", "4 Lacs", "5 Lacs", "7.5 Lacs", "10 Lacs", "15 Lacs", "20 Lacs", ],
    },
  ];

  const PLAN_PLACEHOLDER = "Select Plan to Compare";
  const COMPANY_PLACEHOLDER = "Select Company to Compare";
  const PRODUCT_PLACEHOLDER = "Select Product to Compare";
  const SUM_INSURED_PLACEHOLDER = "Select Sum Insured";
  const DEDUCTIBLE_PLACEHOLDER = "Select Deductible Amount";
  const POLICY_PERIOD_PLACEHOLDER = "Select Policy Period";
  const planData = [
    ...new Set(jsonData?.map((item) => item["Insurance Plan"])),
  ];

  console.warn("planData", jsonData?.map((item) => item["Insurance Plan"]))

  const customSort = (a, b) => {
    if (
      a["Insurance Plan"] === "Base Plan" &&
      b["Insurance Plan"] === "Super Topup Plan"
    ) {
      return -1; // "Base Plan" comes before "Super Topup Plan"
    } else if (
      a["Insurance Plan"] === "Super Topup Plan" &&
      b["Insurance Plan"] === "Base Plan"
    ) {
      return 1; // "Super Topup Plan" comes after "Base Plan"
    } else {
      return 0; // No change in order
    }
  };

  const handlePlanChange = (index, value) => {
    const updatedItems = [...initialData];
    updatedItems[index] = {};
    updatedItems[index]["Insurance Plan"] = value;
    setInitialData(updatedItems.sort(customSort));
  };

  const handleCompanyChange = (index, value) => {
    const updatedItems = [...initialData];
    const selectedItem = {};
    selectedItem["Insurance Plan"] = updatedItems[index]["Insurance Plan"];
    selectedItem["Insurance Company"] = value;
    updatedItems[index] = selectedItem;
    setInitialData(updatedItems);
  };

  const handleProductChange = (index, value) => {
    const companyList = jsonData.filter(
      (item) => item["Product Name"] === value
    );
    const newData = [...initialData];
    newData[index] = companyList[0];
    setInitialData(newData);
  };

  const handleSumInsuredChange = (index, value) => {
    // let updatedItems = [...initialData];
    // updatedItems[index]["Sum Insured"] = value;

    const updatedItems = initialData.map((item, i) =>
    i === index ? { ...item, "Sum Insured": value } : item  );


    const testFilter = premiumData?.find(
      (item) => item["Product Name"] === updatedItems[index]["Product Name"]
    )?.["Policy Period"][updatedItems[index]["Policy Period"]]["Premium Data"][
      value
    ];
    
    updatedItems[index]["Unique Features"] = testFilter;
    setInitialData(updatedItems);
  };

  const handleDeductibleAmountChange = (index, value) => {
    // let updatedItems = [...initialData];
    // updatedItems[index]["Deductible"] = value;

    const updatedItems = initialData.map((item, i) =>
    i === index ? { ...item, "Deductible": value } : item
  );

    setInitialData(updatedItems);
  };

  const handlePolicyPeriodChange = (index, value) => {
    // let updatedItems = [...initialData];
    // updatedItems[index]["Policy Period"] = value;

    const updatedItems = initialData.map((item, i) =>
      i === index ? { ...item, "Policy Period": value } : item
    );

    if (updatedItems[index]["Sum Insured"]) {
      const testFilter = premiumData?.find(
        (item) => item["Product Name"] === updatedItems[index]["Product Name"]
      )?.["Policy Period"][value]["Premium Data"][
        updatedItems[index]["Sum Insured"]
      ];
      updatedItems[index]["Unique Features"] = testFilter;
    }
    setInitialData(updatedItems);
  };

  const handleButtonClick = () => {
    setInitialData([...initialData, {}]);
  };

  const handleDeleteColumn = (indexToRemove) => {
    setInitialData(initialData.filter((_, index) => index !== indexToRemove));
  };

  function removeExtraSpace(text) {
    let textParts = text.split(" ");
    let noSpacesText = textParts.join("");
    return noSpacesText;
  }

  const handleSavePdf = () => {
    if (productSuggested === "") {
      alert("Please select Preferred Product");
    } else {
      setUploading(true);
      const data = {
        quoteId: activeQuote?.id,
        quoteDetails: initialData,
        preferredProduct: productSuggested,
        status: "Pending",
        addedByUserId: agentAuthInfo?.auth_profile?.user_id,
        token: agentAuthToken,
        groupId: agentAuthInfo?.activeCustomer?.groupId,
      };

      dispatch(updateCustomerQuoteProposal(data)).then((response) => {
        if (response?.payload?.data?.success === true) {
          setActiveQuote(response?.payload?.data?.quoteData);
          message.success("Quote Saved Successfully");
          setUploading(false);
        } else {
          message.warning("Error! Please try again later");
          setUploading(false);
        }
      });
    }
  };

  function capitalizeFirstLetter(string) {
    return string.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  useEffect(() => {
    setUploading(true);
    const data = {
      quoteId: activeQuote?.id,
      token: agentAuthToken,
      customerId: activeQuote?.customerId,
    };
    dispatch(fetchCustomerSingleQuote(data))
      .then((result) => {
        if (result?.payload?.data?.success === true) {
          if (result?.payload?.data?.quoteData?.preferredProduct) {
            setproductSuggested(
              result?.payload?.data?.quoteData?.preferredProduct
            );
          }
          if (result?.payload?.data?.quoteData?.quoteDetails?.length > 0) {
            setInitialData(result?.payload?.data?.quoteData?.quoteDetails);
          }
          message.success("Quote fetched Successfully!");
          setUploading(false);
        } else {
          setUploading(false);
        }
      })
      .catch((error) => {
        message.warning("something went wrong!");
        setUploading(false);
      });
  }, [agentAuthToken, dispatch]);

  const handleInputChange = (dataIndex, tableRowKey, e) => {
    setInitialData((prevData) => {
      const updatedItems = [...prevData];
      updatedItems[dataIndex] = {
        ...updatedItems[dataIndex],
        [tableRowKey]: e.target.value,
      };
      return updatedItems;
    });
  };

  const onFinish = () => {
    handleSavePdf();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleUniqueFeaturesChange = (dataIndex, key, e) => {
    const updatedText = e.target.value;

    // Split the input by lines and remove bullet points before passing it back to the handler
    const updatedFeatures = updatedText
      .split("\n")
      .map((line) => line.replace(/^•\s*/, ""));

    setInitialData((prevData) => {
      const updatedItems = [...prevData];
      updatedItems[dataIndex] = {
        ...updatedItems[dataIndex],
        [key]: updatedFeatures,
      };
      return updatedItems;
    });
  };

  const transformedData = (tranformedCols) => {
    // Step 1: Transform the data
    return tranformedCols?.map((key, rowIndex) => {
      const row = { key: rowIndex, column: key };
      initialData.forEach((insuranceData, colIndex) => {
        row[`col${colIndex}`] = insuranceData[key];
      });
      return row;
    });
  };

  // Step 2: Define the columns for the transformed data
  const transformedColumns = [
    {
      title: "Attributes",
      dataIndex: "column",
      key: "column",
      fixed: "left",
      width: 200,
      render: (text) => {
        return (
          <div style={{ fontWeight: "bold", textAlign: "left" }}>{text}</div>
        );
      },
      onCell: () => {
        return {
          style: {
            backgroundColor: "#FAF5EB",
          },
        };
      },
    },
    ...initialData?.map((_, colIndex) => ({
      title: `Plan ${colIndex + 1}`,
      dataIndex: `col${colIndex}`,
      key: `col${colIndex}`,
      width: 200,
      render: (text, record) => {
        const dataIndex = colIndex;
        const key = record.column;
        if (key === "Product Name") {
          return (
            <Select
              bordered={false}
              style={{ width: "99%", padding: "0px" }}
              placeholder={PRODUCT_PLACEHOLDER}
              onChange={(selectedValue) =>
                handleProductChange(dataIndex, selectedValue)
              }
              options={[
                ...new Set(
                  jsonData
                    ?.filter(
                      (item) =>
                        item["Insurance Company"] ===
                          initialData[dataIndex]["Insurance Company"] &&
                        item["Insurance Plan"] ===
                          initialData[dataIndex]["Insurance Plan"]
                    )
                    ?.map((product) => product["Product Name"])
                ),
              ]?.map((productName) => ({
                label: productName,
                value: productName,
              }))}
              value={text || PRODUCT_PLACEHOLDER}
            />
          );
        } else if (key === "Insurance Plan") {
          return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Select
                bordered={false}
                value={text || PLAN_PLACEHOLDER}
                placeholder={PLAN_PLACEHOLDER}
                style={{ width: "79%", padding: "0px" }}
                onChange={(selectedValue) =>
                  handlePlanChange(dataIndex, selectedValue)
                }
                options={planData?.map((plan) => ({
                  label: plan,
                  value: plan,
                }))}
              />
              <Tooltip
                title={initialData.length === 1 ? "Disabled" : "Delete Plan"}
              >
                <Button
                  style={{ justifySelf: "center", flex: "col" }}
                  onClick={() =>
                    initialData.length === 1
                      ? ""
                      : handleDeleteColumn(dataIndex)
                  }
                >
                  <DeleteTwoTone twoToneColor="#dc3545" />
                </Button>
              </Tooltip>
            </div>
          );
        } else if (key === "Sum Insured") {
          return (
            <Select
              bordered={false}
              style={{ width: "100%", padding: "0px" }}
              placeholder={SUM_INSURED_PLACEHOLDER}
              onChange={(selectedValue) =>
                handleSumInsuredChange(dataIndex, selectedValue)
              }
              value={text || SUM_INSURED_PLACEHOLDER}
              options={Object.keys(
                (premiumData?.find(
                  (item) =>
                    item["Product Name"] ===
                    initialData[dataIndex]["Product Name"]
                )?.["Policy Period"] || {})[
                  initialData[dataIndex]["Policy Period"]
                ]?.["Premium Data"] || {}
              )?.map((sumInsured) => ({
                label: sumInsured,
                value: sumInsured,
              }))}
            />
          );
        } else if (key === "Deductible") {
          return (
            <Select
              bordered={false}
              style={{ width: "100%", padding: "0px" }}
              placeholder={DEDUCTIBLE_PLACEHOLDER}
              onChange={(selectedValue) =>
                handleDeductibleAmountChange(dataIndex, selectedValue)
              }
              value={text || DEDUCTIBLE_PLACEHOLDER}
              options={premiumData
                ?.find(
                  (item) =>
                    item["Product Name"] ===
                    initialData[dataIndex]["Product Name"]
                )
                ?.["Deductible"]?.map((sumInsured) => ({
                  label: sumInsured,
                  value: sumInsured,
                }))}
            />
          );
        } else if (key === "Policy Period") {
          return (
            <Select
              bordered={false}
              style={{ width: "100%", padding: "0px" }}
              placeholder={POLICY_PERIOD_PLACEHOLDER}
              onChange={(selectedValue) =>
                handlePolicyPeriodChange(dataIndex, selectedValue)
              }
              value={text || POLICY_PERIOD_PLACEHOLDER}
              options={Object.keys(
                premiumData?.find(
                  (item) =>
                    item["Product Name"] ===
                    initialData[dataIndex]["Product Name"]
                )?.["Policy Period"] || {}
              )?.map((policyPeriod) => ({
                label: policyPeriod,
                value: policyPeriod,
              }))}
            />
          );
        } else if (key === "Premium") {
          return (
            <Input
              onChange={(e) => handleInputChange(dataIndex, key, e)}
              value={text}
              placeholder="Please enter Premium"
              style={{
                background: "none",
                width: "100%",
                border: "none",
                outline: "none",
              }}
            />
          );
        } else if (key === "Insurance Company") {
          return (
            <Select
              bordered={false}
              style={{ width: "100%", padding: "0px" }}
              placeholder={COMPANY_PLACEHOLDER}
              onChange={(selectedValue) =>
                handleCompanyChange(dataIndex, selectedValue)
              }
              value={text || COMPANY_PLACEHOLDER}
              options={[
                ...new Set(
                  jsonData
                    ?.filter(
                      (item) =>
                        item["Insurance Plan"] ===
                        initialData[dataIndex]["Insurance Plan"]
                    )
                    ?.map((company) => company["Insurance Company"])
                ),
              ]?.map((company) => ({ label: company, value: company }))}
            />
          );
        } else if (key === "Preferred Product") {
          return (
            <div>
              <p
                style={{
                  marginBottom: "20px",
                }}
              >
                <Checkbox
                  disabled={
                    initialData[dataIndex]["Premium"] === "" ||
                    initialData[dataIndex]["Premium"] === undefined
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    handlePreferredProductChange(dataIndex);
                  }}
                  checked={
                    initialData[dataIndex]["Preferred Product"] === "Yes"
                      ? true
                      : false
                  }
                >
                  Select If Preferred Product
                </Checkbox>
              </p>
            </div>
          );
        } else if (key === "Unique Features") {
          
        console.log("text && text.length", text )
         return text && text.length && <TextArea
            style={{
              background: "none",
              width: "100%",
              border: "none",
              outline: "none",
            }}
            rows={text && text?.length || 2}
            onChange={(e) => {
              handleUniqueFeaturesChange(dataIndex, key, e);
            }}
            value={text && text?.map((feature) => `• ${feature}`).join("\n")}
          />
          
        } else {
          return (
            <TextArea
              style={{
                background: "none",
                width: "100%",
                border: "none",
                outline: "none",
              }}
              onChange={(e) => handleInputChange(dataIndex, key, e)}
              value={text}
            />
          );
        }
      },
      onCell: () => {
        return {
          style: {
            backgroundColor:
              initialData[colIndex]["Insurance Plan"] === "Super Topup Plan"
                ? "#FCFCCC"
                : "",
          },
        };
      },
    })),
    {
      title: "Action",
      key: "action",
      width: 200,
      onCell: (_, index) => {
        if (index === 7) {
          return {
            rowSpan: 1,
          };
        } else {
          return {
            rowSpan: 0,
          };
        }
      },
      render: (_, index) => {
        if (index.key === 7 && index.column === "Preferred Product") {
          return (
            <div span={3} style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ justifySelf: "center", flex: "col", margin: "15px" }}
                type="primary"
                onClick={handleButtonClick}
              >
                Add Plan to Compare
              </Button>
            </div>
          );
        }
      },
    },
  ];

  const handlePreferredProductChange = (index) => {
    const updatedItems = [...initialData]?.map((item, idx) => {
      const updatedItem = { ...item };
      updatedItem["Preferred Product"] = "No";
      if (idx === index) {
        updatedItem["Preferred Product"] = "Yes";
        setproductSuggested(updatedItem["Product Name"]);
      }
      return updatedItem;
    });
    setInitialData(updatedItems);
  };

  const removeSpacesFromKeys = (obj) => {
    if (typeof obj !== "object" || obj === null) {
      return obj; // Return the value if obj is not an object
    }

    if (Array.isArray(obj)) {
      return obj.map(removeSpacesFromKeys); // Recursively handle array elements
    }

    return Object.keys(obj).reduce((acc, key) => {
      const newKey = key.replace(/\s+/g, "");
      acc[newKey] = removeSpacesFromKeys(obj[key]); // Recursively handle nested objects
      return acc;
    }, {});
  };

  const handleGeneratePdf = () => {
    setUploading(true);
    const result = removeSpacesFromKeys(activeQuote);

    result?.quoteDetails?.forEach((quote) => {
      if (quote?.UniqueFeatures) {
        quote.UniqueFeatures = quote?.UniqueFeatures?.map(
          (feature, index) => `• ${feature}\n`
        );
      }
    });

    result.proposerEmail = `${activeQuote?.customer?.email}`;
    result.proposerName = `${activeQuote?.customer?.firstName} ${activeQuote?.customer?.lastName}`;

    // fetch("http://198.154.99.121:3000/generate-document", {
    fetch("https://carbone.kokoroinsurance.com/generate-document", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(result),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((err) => {
            setUploading(false);
            throw err;
          });
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "result.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setUploading(false);
      })
      .catch((error) => {
        setUploading(false);
        console.error("Error:", error);
      });
  };

  // const columns = transformedColumns.map(column => ({
  //   ...column,
  //   onHeaderCell: () => ({
  //     style: {
  //       backgroundColor: "#D9AB2D", // Change to your desired color
  //       color: "white", // Change the text color if needed
  //     }
  //   })
  // }));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      <LoadingSpinner loading={uploading} />

      <Form
        layout="vertical"
        form={form}
        name="Quote Comparison Form"
        style={{
          width: "100%",
        }}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div ref={reportTemplateRef} style={{ justifyContent: "center" }}>
          <Row gutter={16}>
            <Col span={12}>
              <Card
                title="All About You"
                style={{ border: "1px solid black", marginBottom: "3%" }}
                headStyle={{
                  backgroundColor: "#D9AB2D",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Name Of the Proposer">
                      <Input
                        disabled
                        value={
                          activeQuote?.customer?.firstName +
                          " " +
                          activeQuote?.customer?.lastName
                        }
                        type="text"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Proposer Email">
                      <Input
                        disabled
                        value={activeQuote?.customer?.email}
                        type="text"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Family Composition">
                      <Select disabled value={activeQuote?.familyComposition} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Age of Members (Years)">
                      <Input
                        disabled
                        value={activeQuote?.proposerFamilyMembersAge}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="BQP">
                      <Select disabled value={activeQuote?.agentBQP} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Date Of Quote">
                      <DatePicker
                        disabled
                        style={{ width: "100%" }}
                        value={
                          activeQuote?.quoteDate
                            ? dayjs(activeQuote?.quoteDate)
                            : null
                        }
                        format={dateFormat}
                        minDate={dayjs(oneYearAgo, dateFormat)}
                        maxDate={dayjs(today, dateFormat)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Table
            style={{
              marginBottom: "3%",
              border: "1px solid black",
              borderRadius: "5px",
            }}
            bordered
            columns={transformedColumns}
            dataSource={transformedData(tabColumns1)}
            pagination={false}
            scroll={{ x: "max-content" }}
            title={() => (
              <h3
                style={{
                  color: "white",
                  padding: "16px",
                  backgroundColor: "#D9AB2D",
                  textAlign: "center",
                  margin: "0px",
                }}
              >
                Plans Best Suited for You
              </h3>
            )}
            sticky={{
              offsetHeader: 0,
            }}
          />

          <Table
            style={{
              marginBottom: "3%",
              border: "1px solid black",
              borderRadius: "5px",
            }}
            bordered
            columns={transformedColumns}
            dataSource={transformedData(tabColumns2)}
            pagination={false}
            scroll={{ x: "max-content" }}
            title={() => (
              <h3
                style={{
                  color: "white",
                  padding: "16px",
                  backgroundColor: "#D9AB2D",
                  textAlign: "center",
                  margin: "0px",
                }}
              >
                The Benefits You Receive
              </h3>
            )}
            sticky={{
              offsetHeader: 0,
            }}
          />

          <FloatButton.Group
            icon={<SettingOutlined />}
            tooltip={<div>More Options</div>}
            trigger="hover"
            type="primary"
            shape="circle"
            style={{ right: 55 }}
          >
            <FloatButton
              icon={<SaveOutlined gutter={16} />}
              tooltip={<div>Save Quote</div>}
              type="primary"
              shape="circle"
              onClick={handleSavePdf}
            />

            <FloatButton
              icon={<DownloadOutlined />}
              tooltip={<div>Download Quote</div>}
              type="primary"
              shape="circle"
              onClick={handleGeneratePdf}
            />
          </FloatButton.Group>
        </div>
      </Form>
    </div>
  );
};

export default QuoteComparison;
